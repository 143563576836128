var slideWrapper = jQuery("#carousel-fullwidth"),
    iframes = slideWrapper.find('.embed-player'),
    lazyImages = slideWrapper.find('.slide-image'),
    lazyCounter = 1;
// POST commands to YouTube or Vimeo API
function postMessageToPlayer(player, command){
  if (player == null || command == null){
    return;
  } 
  player.contentWindow.postMessage(JSON.stringify(command), "*");
}
 
// When the slide is changing
function playPauseVideo(slick, control){
  var currentSlide, slideType, startTime, player, video;

  currentSlide = slick.find(".slick-current");
  slideType = currentSlide.attr("class").split(" ")[1];
  player = currentSlide.find("iframe").get(0);
  startTime = currentSlide.data("video-start");

  if (slideType === "vimeo") {
    switch (control) {
      case "play":
        if ((startTime != null && startTime > 0 ) && !currentSlide.hasClass('started')) {
          currentSlide.addClass('started');
          postMessageToPlayer(player, {
            "method": "setCurrentTime",
            "value" : startTime
          });
        }
        postMessageToPlayer(player, {
          "method": "play",
          "value" : 1
        });
        break;
      case "pause":
        postMessageToPlayer(player, {
          "method": "pause",
          "value": 1
        });
        break;
    }
  } else if (slideType === "youtube") {
    switch (control) {
      case "play":
        postMessageToPlayer(player, {
          "event": "command",
          "func": "mute"
        });
        postMessageToPlayer(player, {
          "event": "command",
          "func": "playVideo"
        });
        break;
      case "pause":
        postMessageToPlayer(player, {
          "event": "command",
          "func": "pauseVideo"
        });
        break;
    }
  } else if (slideType === "video") {
    video = currentSlide.children("video").get(0);
    if (video != null) {
      if (control === "play"){
        video.play();
      } else {
        video.pause();
      }
    }
  }
}

// Resize player

function resizePlayer(iframes, newratio) {
  if (!iframes[0]) {
    return;
  }
  var win = jQuery("#carousel-fullwidth"),
      width = win.width(),
      playerWidth,
      height = win.height(),
      playerHeight;
      

  iframes.each(function(){
    var current = jQuery(this);
    if (width / newratio < height) {
      playerWidth = Math.ceil(height * newratio);
      current.width(playerWidth).height(height).css({
        left: (width - playerWidth) / 2,
         top: 0
        });
    } else {
      playerHeight = Math.ceil(width / newratio);
      current.width(width).height(playerHeight).css({
        left: 0,
        top: (height - playerHeight) / 2
      });
    }
  });
}

// DOM Ready
jQuery(function() {
  // Initialize
  if ( 0 < slideWrapper.length ){
    slideWrapper.on("init", function(slick){
      slick = jQuery(slick.currentTarget);
      setTimeout(function(){
        playPauseVideo(slick,"play");
      }, 1000);
      resizePlayer(iframes, 16/9);
    });
    slideWrapper.on("setPosition", function(){
      resizePlayer(iframes, 16/9);
    });
    slideWrapper.on("beforeChange", function(event, slick) {
      slick = jQuery(slick.$slider);
      setTimeout(function(){
        playPauseVideo(slick,"pause");
      },100);
    
    });
    slideWrapper.on("afterChange", function(event, slick) {
      slick = jQuery(slick.$slider);
      playPauseVideo(slick,"play");
    });
    slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
      lazyCounter++;
      if (lazyCounter === lazyImages.length){
        lazyImages.addClass('show');
        // slideWrapper.slick("slickPlay");
      }
    });

    //start the slider
    slideWrapper.slick({
      infinite: true,
      autoplay: true,
      pauseOnFocus : false,
      swipe : true,
      autoplaySpeed:4000,
      speed: 300,
      fade: true,
      cssEase: 'linear',
      dots:true,
      prevArrow: '<button class="carousel__prev icon-precedant"></button>',
      nextArrow: '<button class="carousel__next icon-suivant"></button>'
    });
  }
});

